<template>
  <b-modal
    id="rename-report-modal"
    ref="rename-report-modal"
    centered
    title="Rename report"
    ok-title="Save Changes"
    cancel-variant="outline-secondary"
    no-close-on-backdrop
    @show="setModalData"
    @hidden="resetModal"
    @ok="saveChanges"
  >
    <b-overlay
      id="overlay-background"
      :show="isLoading"
      variant="white"
      opacity="1"
      rounded="sm"
    >
      <validation-observer
        ref="renameReportsRules"
        tag="form"
      >
        <b-row>
          <b-col
            cols="12"
          >
            <b-form-group
              label="Name"
            >
              <validation-provider
                #default="{ errors }"
                name="Name"
                rules="required"
              >
                <b-form-input
                  v-model="reportForRename.name"
                  placeholder="Name here"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </validation-observer>
    </b-overlay>
  </b-modal>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BOverlay,
} from 'bootstrap-vue'

import useReportDetails from '@/views/admin/reports-v2/reports/report-details/useReportDetails'

export default {
  name: 'RenameReportModal',
  components: {
    ValidationProvider,
    ValidationObserver,

    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BOverlay,
  },
  props: {
    report: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      reportForRename: null,
      isLoading: false,
      required,
    }
  },
  setup() {
    const {
      updateReport,
    } = useReportDetails()

    return {
      updateReport,
    }
  },
  methods: {
    setModalData() {
      this.reportForRename = JSON.parse(JSON.stringify(this.report))
    },
    resetModal() {
      this.reportForRename = null
    },
    saveChanges(bvModalEvt) {
      bvModalEvt.preventDefault()

      return new Promise(() => {
        this.$refs.renameReportsRules.validate().then(success => {
          if (success) {
            this.isLoading = true

            this.updateReport(this.reportForRename.id, {
              name: this.reportForRename.name,
              report_view_id: this.reportForRename.reportView.id,
              selected_fields: this.reportForRename.selected_fields,
            }).then(() => {
              this.isLoading = false
              this.$emit('refetchData')
              this.$bvModal.hide('rename-report-modal')
            })
          }
        })
      })
    },
  },
}
</script>
