<template>

  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
    >

      <div class="table-header m-2">

        <!-- Table Top -->
        <b-row>
          <b-col
            cols="12"
            class="mb-2"
          >
            <h4
              v-if="selectedFolder"
            >
              <feather-icon
                class="text-primary cursor-pointer"
                icon="ArrowLeftIcon"
                size="19"
                @click="backToPrevPage"
              />
              {{ selectedFolder.name }}
            </h4>
            <h4 v-else>
              Root Reports
            </h4>
          </b-col>
          <!-- Per Page -->
          <b-col
            cols="12"
            class="d-flex align-items-center justify-content-between mb-1 mb-md-0"
          >
            <div>
              <label class="mr-1 mb-0">Show</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                :reduce="option => option.value"
                class="per-page-selector d-inline-block ml-50 mr-1"
              />
            </div>

            <!-- Search -->
            <div class="d-flex align-items-center justify-content-end">
              <label class="mr-1 mb-0">Search</label>
              <b-form-input
                v-model="searchQuery"
                placeholder="Search"
                class="w-25"
              />

              <b-button
                v-if="canCreateReport"
                class="ml-1"
                variant="primary"
                :to="{ name: 'admin-report-details', query: { folder_id: selectedFolderId } }"
              >
                <span class="mr-25 align-middle text-light">Create Report</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-overlay
        id="overlay-background"
        :show="isLoading"
        variant="white"
        opacity="1"
        rounded="sm"
      >
        <div class="position-relative table-responsive table-sticky-head b-table-sticky-header" style="max-height: 70vh; min-height: 30vh">
          <table class="table b-table table-hover">
            <thead>
              <tr>
                <th
                  v-for="(col, index) in tableColumns"
                  :key="index"
                >{{ col.label }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in tableItems"
                :id="item.id"
                :key="item.id"
                :draggable="canUpdateReport"
                @dragstart="dragStart($event, item.id)"
              >
                <!-- Column: name -->
                <td class="handle">
                  <b-link
                    :to="{ name: 'admin-report-details', query: { id: item.id } }"
                  >{{ item.name }}
                  </b-link>
                </td>

                <!-- Column: report type -->
                <td><span v-if="item.reportView">{{ item.reportView.name }}</span></td>

                <!-- Column: created by -->
                <td><span v-if="item.user_id">{{ item.user_id.full_name }}</span></td>

                <!-- Column: created at -->
                <td>{{ item.created_at | longDateTime }}</td>

                <!-- Column: filtered_by_program -->
                <td>
                  <b-badge
                    :variant="`light-${resolveStatusVariant(item.reportView.with_program_filter)}`"
                  >
                    {{ item.reportView.with_program_filter }}
                  </b-badge>
                </td>
                <td>
                  <!-- Dropdown -->
                  <b-dropdown
                    variant="link"
                    toggle-class="mx-auto p-0"
                    no-caret
                    :right="$store.state.appConfig.isRTL"
                  >

                    <template #button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="align-middle text-body"
                      />
                    </template>

                    <b-dropdown-item
                      v-for="option in actionOptions"
                      :key="option.value"
                      :to="{ name: option.link, query: { id: item.id } }"
                    >
                      <span>{{ option.title }}</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      v-if="canUpdateReport"
                      @click="showRenameReportModal(item)"
                    >
                      <span>Rename</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      v-if="canCreateReport"
                      @click="cloneReportAndUpdateTable(item.id)"
                    >
                      <span>Clone</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      v-if="canDeleteReport"
                      @click="openDeleteConfirmation(item.id)"
                    >
                      <span>Delete</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      v-if="canExportCSV"
                      v-b-modal.export-report
                      @click="exportReportCSV(item)"
                    >
                      <span>Export</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      v-if="canUpdateReport"
                      no-caret
                      @click="moveTo(item)"
                    >
                      <span>Move to</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-overlay>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-if="totalItems && perPage !== 'all'"
              v-model="currentPage"
              :total-rows="totalItems"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

          <!-- Export Report -->
          <b-modal
            id="export-report"
            ref="export-report"
            ok-title="Save"
            :ok-disabled="!csvFile"
            cancel-variant="outline-secondary"
            centered
            title="Export Report"
            no-close-on-backdrop
            @close="resetFile"
            @ok="saveCSV"
          >
            <div
              v-if="isLoading"
              class="text-center"
            >
              <b-spinner
                small
              />
            </div>
            <span v-else>{{ csvName }}.csv</span>
          </b-modal>

        </b-row>
      </div>

    </b-card>

    <move-to-folder-modal
      :item="selectedReport"
      :folder-type="'report'"
      @refetchData="refetchData"
    />

    <confirmation-modal
      :toggle-modal="confirmationModalToggle"
      :type="'-delete-report'"
      :title="'Are you sure'"
      :message="'Are you sure you want to delete this report?'"
      @close-modal="confirmationModalToggle = !confirmationModalToggle"
      @confirm="deleteReportIfExist"
    />

    <rename-report-modal
      :report="selectedReport"
      @refetchData="refetchData"
    />
  </div>

</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BPagination, VBModal,
  BDropdown, BDropdownItem, BButton, BLink, BSpinner, BBadge, BOverlay,
} from 'bootstrap-vue'
import { onUnmounted } from '@vue/composition-api'
import { downloadExportFile } from '@core/mixins/downloadExportFile'
import { filters } from '@core/mixins/filters'
import moment from 'moment'

import vSelect from 'vue-select'
import store from '@/store'
import storeModule from '@/views/admin/reports-v2/reportStoreModule'
import useReportsList from '@/views/admin/reports-v2/reports/reports-list/useReportsList'
import MoveToFolderModal from '@/views/components/folders-list/modals/MoveToFolderModal.vue'
import setPerPage from '@/helpers/setPerPage'
import { DEFAULT_PER_PAGE } from '@/constants/base'
import router from '@/router'
import ConfirmationModal from '@/views/components/confirmation/ConfirmationModal.vue'
import RenameReportModal from '@/views/admin/reports-v2/modals/RenameReportModal.vue'

export default {
  name: 'ReportsList',
  components: {
    RenameReportModal,
    ConfirmationModal,
    MoveToFolderModal,
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BPagination,
    BDropdown,
    BDropdownItem,
    BButton,
    BLink,
    BSpinner,
    BBadge,

    vSelect,
  },
  directives: {
    'b-modal': VBModal,
  },
  filters: {
    formatDate(value) {
      return value ? moment(value).format('YYYY-MM-DD') : ''
    },
  },
  mixins: [downloadExportFile, filters],
  data() {
    return {
      isStudentsExporting: false,
      isParentsExporting: false,
      selectedReportName: null,
      csvFile: null,
      selectedReport: {},
      confirmationModalToggle: false,
      deletableReportId: false,
    }
  },
  setup(props, { root }) {
    const INVOICE_APP_STORE_MODULE_NAME = 'app-reports'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    const {
      fetchReportsList,
      tableColumns,
      selectedFolder,
      perPage,
      currentPage,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      totalItems,
      tableItems,
      isSortDirDesc,
      isLoading,
      refReportsListTable,

      actionOptions,

      refetchData,
      cloneReport,
      deleteReport,
      exportReport,
      exportStudentsApplications,
      exportParentsTransactions,

      resolveStatusVariant,

      canCreateReport,
      canDeleteReport,
      canUpdateReport,
      canExportCSV,
    } = useReportsList(root, props)

    return {
      fetchReportsList,
      tableColumns,
      selectedFolder,
      perPage,
      currentPage,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      totalItems,
      tableItems,
      isSortDirDesc,
      isLoading,
      refReportsListTable,

      actionOptions,

      refetchData,
      cloneReport,
      deleteReport,
      exportReport,
      exportStudentsApplications,
      exportParentsTransactions,

      resolveStatusVariant,

      canCreateReport,
      canDeleteReport,
      canUpdateReport,
      canExportCSV,
    }
  },
  computed: {
    forceReload() {
      return store.getters['app-folders/getForceReload']
    },
    selectedFolderId() {
      return this.selectedFolder ? this.selectedFolder.id : null
    },
    csvName() {
      return `${this.selectedReportName}-report-${moment().format('YYYY-MM-DD')}`
    },
    programId() {
      return store.getters['verticalMenu/getDefaultProgram']
    },
  },
  watch: {
    forceReload() {
      this.refetchData()
    },
    selectedFolder() {
      this.refetchData()
    },
    $route() {
      if (!this.$route.query.currentPage) {
        this.setFilterParams()
      }
    },
  },
  async created() {
    this.setFilterParams()
    // Update filters
    window.onpopstate = () => {
      this.setFilterParams()
    }
    await this.fetchReportsList()
  },
  methods: {
    async cloneReportAndUpdateTable(id) {
      await this.cloneReport(id)
      this.refetchData()
    },
    openDeleteConfirmation(itemId) {
      this.deletableReportId = itemId
      this.$bvModal.show('confirmation-delete-report')
    },
    async deleteReportIfExist() {
      this.isLoading = true
      await this.deleteReport(this.deletableReportId)
      this.$bvModal.hide('confirmation-delete-report')
      this.$store.commit('app-folders/TOGGLE_FORCE_RELOAD')
      this.isLoading = false
    },
    showRenameReportModal(report) {
      this.selectedReport = report
      this.$nextTick(() => {
        this.$bvModal.show('rename-report-modal')
      })
    },
    resetFile() {
      this.csvFile = null
    },
    async exportReportCSV(report) {
      this.isLoading = true
      this.selectedReportName = report.name

      const queryParams = {
        report_view_id: report.reportView.id,
        selected_fields: report.selected_fields,
        filters: report.filters,
        group_by: report.group_by,
      }

      if (report.reportView.with_program_filter) {
        queryParams.program_id = this.programId
      }

      await this.exportReport(queryParams)
        .then(response => {
          this.isLoading = false
          this.csvFile = response.data
        })
    },
    saveCSV() {
      this.downloadFile(this.csvFile, this.csvName, 'csv')
    },
    setFilterParams() {
      const query = { ...router.currentRoute.query }
      const defaultPerPage = localStorage.getItem('reportsPerPage')
      this.currentPage = Number(query.reportsCurrentPage) || 1
      this.perPage = setPerPage(query.reportsPerPage, defaultPerPage, DEFAULT_PER_PAGE)
      this.searchQuery = query.reportsSearchQuery || ''
    },
    async moveTo(report) {
      this.selectedReport = report
      this.$nextTick(() => {
        this.$bvModal.show('move-to-folder-modal')
      })
    },
    dragStart(event, id) {
      if (this.canUpdateReport) {
        const data = {
          id,
          type: 'report',
        }
        event.dataTransfer.setData('text/plain', JSON.stringify(data))
      } else {
        event.preventDefault()
      }
    },
    backToPrevPage() {
      store.commit('app-folders/SET_SELECTED_FOLDER', null)

      this.$router.push({ name: 'admin-reports-folders' })
    },
  },
}
</script>

<style lang="scss" scoped>
.table-header {

  label {
    margin-bottom: 0;
  }
}

.badge {
  text-transform: uppercase;
}

.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
